<template>
  <div class="info">
    <h1>Nerina's SC-Overlay</h1>
    <p>
      BTW, If you didn't request Nerina to create a sc-overay something, how did you find this page? :(
    </p>
    <div class="line"></div>
    <div id="setting">
      <h2>Change Port about StreamCompanion Web Overlay</h2>
      <div class="ports">
        <vs-input type="number" color="#7d33ff" v-model="port" placeholder="Port"></vs-input>
        <vs-button
          color="#ff3e4e"
          circle
          icon
          floating
          @click="portHandler()"
        >
          <i class="fa-duotone fa-rotate"></i>
        </vs-button>
      </div>
    </div>
    <div class="line"></div>
    <div id="nav">
      <h2>Overlay List</h2>
      <router-link to="/rika">Rika sc-overlay</router-link>
      <router-link to="/test">(TESTED) Key Overlay</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Info',
  data () {
    return { 
      port: 0
    }
  },
  created () {
    var vm = this
    vm.port = localStorage.getItem('sc_port') != null ? (localStorage.getItem('sc_port') * 1) : 0
  },
  methods: {
    portHandler () {
      localStorage.setItem('sc_port', this.port)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.info {
  width: 100vw;
  text-align: center;
  padding: 50px;
  color: #2c3e50;

  h1 {
    font-size: 40px;
    padding: 20px;
  }
  p {
    padding: 10px;
  }
}
#nav {
  padding: 10px;
  display: flex;
  flex-direction: column;

  h2 {
    font-size: 20px;
    margin-bottom: 10px;
  }

  a {
    font-weight: bold;
    color: #2c3e50;
    transition: color 200ms ease;

    &.router-link-exact-active {
      color: #42b983;
    }

    &:hover {
      color: #42b983;
    }
  }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

/* 파이어폭스에서의 초기화 방법 */
input[type=number] {
    -moz-appearance: textfield;
}

#setting {
  padding: 10px;
  color: #2c3e50;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2 {
    font-size: 20px;
    margin-bottom: 10px;
  }

  div.ports { 
    display: flex;
    flex-direction: row;
  }
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.line {
  width: 50vw;
  height: 1px;
  margin: 0 auto;
  background: #000;
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
